import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/alumni-workshops-website/alumni-workshops-website/src/components/workshop-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Signup Starts Friday at Noon PST`}</h1>
    <p>{`We will open up registration for Fall Lovelace Learning Labs sessions on Friday at noon. Check out our `}<a parentName="p" {...{
        "href": "/upcoming_workshops/"
      }}>{`upcoming workshops`}</a>{` page to see what the offerings are!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      